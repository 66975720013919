<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_config.center_setup') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="2"
                          :label="$t('bsri_config.mill_name')"
                          label-for="mill_name_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.mill_info_id"
                              text="text"
                              :options="millInfoList"
                              id="mill_name_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="6"
                          :label="$t('bsri_config.subzone_office_name')"
                          label-for="subzone_office_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.subzone_office_id"
                              text="text"
                              :options="subzoneOfficeList"
                              id="subzone_office_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="center_name"
                          :label="$t('bsri_config.center_name')"
                          >
                          <b-form-input
                              plain
                              v-model="search.center_name"
                              text="text"
                              id="center_name"
                              >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_config.center_setup') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-5 size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_view" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                              <i class="fas fa-toggle-on"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                          </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="xl" :title="editFormTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Edit :id="editItemId" :key="editItemId" @myEvent="loadData"/>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Edit from './Edit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { centerList, centerStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
      Form,
      Edit
  },
  data () {
    return {
      search: {
        mill_type_id: 0,
        mill_info_id: 0,
        subzone_office_id: 0,
        center_name: ''
      },
      rows: [],
      millInfoList: [],
      subzoneOfficeList: []
    }
  },
  mounted () {
  },
  computed: {
    millTypeList: function () {
        const objectData = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
        return objectData.map(obj => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    formTitle () {
      return this.$t('bsri_config.center_setup') + ' ' + this.$t('globalTrans.entry')
    },
    editFormTitle () {
      return this.$t('bsri_config.center_setup') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('bsri_config.mill_name'), class: 'text-center' },
          { label: this.$t('bsri_config.subzone_office_name'), class: 'text-center' },
          { label: this.$t('bsri_config.center_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'mill_name_bn' },
          { key: 'subzone_office_name_bn' },
          { key: 'center_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'mill_name' },
          { key: 'subzone_office_name' },
          { key: 'center_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.mill_type_id': function (newValue) {
      this.millInfoList = this.getMillInfo(newValue)
    },
    'search.mill_info_id': function (newValue) {
      this.subzoneOfficeList = this.getSubzoneOfficeInfo(newValue)
    }
  },
  created () {
    this.loadData()
    this.millInfoList = this.getMillInfo()
    this.subzoneOfficeList = this.getSubzoneOfficeInfo()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, centerStatus, item)
    //   this.changeStatus(incentiveGrantServiceBaseUrl, aggriMaterialStandardStatus, item, 'incentive_grant', 'agMaterialList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, centerList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList
      const subzoneOfficeList = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList

      const listData = data.map(item => {
        const millTypeListObject = millTypeList.find(millType => millType.value === item.mill_type_id)
        const millInfoListObject = millInfoList.find(millInfo => millInfo.value === item.mill_info_id)
        const subzoneListObject = subzoneOfficeList.find(subzone => subzone.value === item.subzone_office_id)

        const centerData = {
          mill_type: millTypeListObject !== undefined ? millTypeListObject.text_en : '',
          mill_type_bn: millTypeListObject !== undefined ? millTypeListObject.text_bn : '',
          mill_name: millInfoListObject !== undefined ? millInfoListObject.text_en : '',
          mill_name_bn: millInfoListObject !== undefined ? millInfoListObject.text_bn : '',
          subzone_office_name: subzoneListObject !== undefined ? subzoneListObject.text_en : '',
          subzone_office_name_bn: subzoneListObject !== undefined ? subzoneListObject.text_bn : ''
        }

        return Object.assign({}, item, centerData)
      })
      return listData
    },
    getMillInfo (id) {
      const dataObject = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return dataObject.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    getSubzoneOfficeInfo (id) {
      const dataObject = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.filter(item => item.status === 1)
      return dataObject.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    }
  }
}
</script>
